<template>
  <Form :heading1="'Update an appointee'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput
            :label="'Name'"
            :defaultValue="appointment.appointee.names"
            @input="setNames"
            :types="'text'"
          />
          <TextInput
            :label="'Email'"
            :defaultValue="appointment.appointee.email"
            @input="setEmail"
            :types="'text'"
          />
          <TextInput
            :label="'Address'"
            :defaultValue="appointment.appointee.location"
            :not="true"
            @input="setAddress"
            :types="'text'"
          />
          <TextInput
            :label="'Title / Institution'"
            :defaultValue="appointment.appointee.title"
            :not="true"
            @input="setTitle"
            :types="'text'"
          />
        </div>

        <div class="flex-1">
          <TextInput
            :label="'Telephone number'"
            :defaultValue="appointment.appointee.mobile"
            @input="setMobile"
            :types="'text'"
          />
          <TextInput  
            :label="'Year of Birth'"
            :defaultValue="appointment.appointee.age || ''"
            @input="setAge"
            :types="'number'"
            :not="true"
          />

            <TextInput  
            :label="'Position'"
            :defaultValue="appointment.appointee.position"
            @input="setPosition"
            :types="'text'"
          />

          
            <TextInput  
            :label="'New Position'"
            :defaultValue="appointment.appointee.newPosition"
            @input="setNewPosition"
            :types="'text'"
            :not="true"
          />
        </div>
      </div>

      <Button :first-l="'Prev'" :last-l="'Next'" @action="doUpdate" />
    </template>
  </Form>
</template>

<script>
import Button from '/src/components/form/Buttons.vue';
import TextInput from '/src/components/form/TextInput.vue';
import Form from '/src/components/form/Form.vue';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'UpdateSecondStep',
  components: {
    Button,
    TextInput,
    Form,
  },
  computed: {
    ...mapState('appointment', { appointment: 'selectedAppointment' }),
  },
  methods: {
    setNames(value) {
      this.appointment.appointee.names = value;
    },
    setEmail(value) {
      this.appointment.appointee.email = value;
    },
    setAddress(value) {
      this.appointment.appointee.location = value;
    },
    setTitle(value) {
      this.appointment.appointee.title = value;
    },
    setMobile(value) {
      this.appointment.appointee.mobile = value;
    },
    setAge(value) {
      this.appointment.appointee.age = value;
    },
    setPosition(value) {
      this.appointment.appointee.position = value;
    },
    setNewPosition(value) {
      this.appointment.appointee.newPosition = value;
    },
    ...mapMutations('appointment', { updateAppointee: 'updateAppointee' }),
    doUpdate() {
      const { names, email, mobile } = this.appointment.appointee;

      if (names == '') {
        this.$store.dispatch('notification/SET_NOTIFICATION', {
          msg: 'Names are required',
          uptime: 1000,
          hasError: true,
        });
      } else if (email == '') {
        this.$store.dispatch('notification/SET_NOTIFICATION', {
          msg: 'email is required',
          uptime: 1000,
          hasError: true,
        });
      } else if (mobile == '') {
        this.$store.dispatch('notification/SET_NOTIFICATION', {
          msg: 'phone number is required',
          uptime: 1000,
          hasError: true,
        });
      } else if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(email).toLowerCase()
        )
      ) {
        this.$store.dispatch('notification/SET_NOTIFICATION', {
          msg: 'Email does not exit',
          uptime: 1000,
          hasError: true,
        });
      } else if (/^\+?\d+$/.test(mobile) == false) {
        this.$store.dispatch('notification/SET_NOTIFICATION', {
          msg: 'Invalid telephone number',
          uptime: 1000,
          hasError: true,
        });
      } else if (mobile.length != 12) {
        this.$store.dispatch('notification/SET_NOTIFICATION', {
          msg: 'telephone number must be 12 digits',
          uptime: 1000,
          hasError: true,
        });
      } else {
        // this.$store.dispatch("notification/SET_NOTIFICATION", {
        //   msg: "Updating appointee details ...",
        //   uptime: 1000,
        // });
        this.updateAppointee(this.appointment.appointee);
      }
    },
  },
};
</script>

<style></style>
