<template>
  <Form :heading1="'Update a profile'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput
            :label="'Title'"
            :defaultValue="appointment.title"
            @input="setTitle"
            :types="'text'"
          />
          <TextInput
            :label="'Description'"
            :defaultValue="appointment.description"
            @input="setDescription"
            :types="'textarea'"
          />
        </div>
        <div class="flex-1">
          <TextInput
            :label="'Address'"
            :defaultValue="appointment.address"
            @input="setAddress"
            :types="'text'"
          />

          <div class="md:inline-flex md:space-x-14">
            <div class="flex-1">
              <TextInput
                :label="'Date'"
                :defaultValue="`${appointment.appointmentDateAndTime[0]}-${appointment.appointmentDateAndTime[1] <=9 ? '0' : ''}${appointment.appointmentDateAndTime[1]}-${appointment.appointmentDateAndTime[2] <=9 ? '0' : ''}${appointment.appointmentDateAndTime[2]}`"
                @input="setDate"
                :types="'date'"
              />
            </div>
            <div class="flex-1">
              <TextInput
                :label="'Time'"
                :defaultValue="`${appointment.appointmentDateAndTime[3] <=9 ? '0' : ''}${appointment.appointmentDateAndTime[3]}:${appointment.appointmentDateAndTime[4] <=9 ? '0' : ''}${appointment.appointmentDateAndTime[4]}`"
                @input="setTime"
                :types="'time'"
              />
            </div>
          </div>
          <TextInput
            :label="'Status'"
            :not="true"
            :defaultValue="appointment.status"
            :types="'select'"
            @input="setStatus"
            :options="[
              { id: 'PENDING', name: 'PENDING' },
              { id: 'COMPLETE', name: 'COMPLETE' },
              { id: 'STARTED', name: 'STARTED' },
              { id: 'POSTPONED', name: 'POSTPONED' },
              { id: 'CANCELLED ', name: 'CANCELLED ' },
            ]"
          />
        </div>
      </div>
      <Button :first-l="'Prev'" :last-l="'Next'" @action="doUpdate" />
    </template>
  </Form>
</template>

<script>
import Button from '/src/components/form/Buttons.vue';
import TextInput from '/src/components/form/TextInput.vue';
import Form from '/src/components/form/Form.vue';
import { mapMutations, mapState } from 'vuex';
import { formatDate } from '/src/functions';

export default {
  name: 'UpdateFirstStep',
  components: {
    Button,
    TextInput,
    Form,
  },
  
  computed: {
    ...mapState('appointment', { appointment: 'selectedAppointment' }),
  },
  created() {
    this.setDate(`${this.appointment.appointmentDateAndTime[0]}-${this.appointment.appointmentDateAndTime[1] <=9 ? '0' : ''}${this.appointment.appointmentDateAndTime[1]}-${this.appointment.appointmentDateAndTime[2] <=9 ? '0' : ''}${this.appointment.appointmentDateAndTime[2]}`);
    this.setTime(`${this.appointment.appointmentDateAndTime[3] <=9 ? '0' : ''}${this.appointment.appointmentDateAndTime[3]}:${this.appointment.appointmentDateAndTime[4] <=9 ? '0' : ''}${this.appointment.appointmentDateAndTime[4]}`);
  },
  methods: {
    setTitle(value) {
      this.appointment.title = value;
    },
    setDescription(value) {
      this.appointment.description = value;
    },
    setAddress(value) {
      this.appointment.address = value;
    },
    setDate(value) {
      this.appointment.dateO = formatDate(value);
    },
    setTime(value) {
      if (typeof value == Object) {
        this.appointment.time = value.target.value;
      } else {
        this.appointment.time = value;
      }
    },
    setStatus(value) {
      if (typeof value == 'object') {
        this.appointment.status = value.target.value;
      } else {
        this.appointment.status = value;
      }
    },
    ...mapMutations('appointment', { updateAppointment: 'updateAppointment' }),

    doUpdate() {
      const {
        address,
        dateO,
        description,
        title,
        time,
        status,
      } = this.appointment;

      if (title == '') {
        this.$store.dispatch('notification/SET_NOTIFICATION', {
          msg: 'appointment title is required',
          uptime: 1000,
          hasError: true,
        });
      } else if (description == '') {
        this.$store.dispatch('notification/SET_NOTIFICATION', {
          msg: 'appointment description is required',
          uptime: 1000,
          hasError: true,
        });
      } else if (address == '') {
        this.$store.dispatch('notification/SET_NOTIFICATION', {
          msg: 'appointment address is required',
          uptime: 1000,
          hasError: true,
        });
      } else if (dateO == null) {
        this.$store.dispatch('notification/SET_NOTIFICATION', {
          msg: 'appointment date is required',
          uptime: 1000,
          hasError: true,
        });
      } else if (time == '') {
        this.$store.dispatch('notification/SET_NOTIFICATION', {
          msg: 'appointment time is required',
          uptime: 1000,
          hasError: true,
        });
      } else {
        let newDate = dateO + ' ' + time+':00';
        this.updateAppointment({
          id: this.$route.params.id,
          address,
          date: newDate,
          description,
          title,
          status,
        });
        // this.$store.dispatch("notification/SET_NOTIFICATION", {
        //   msg: "Updating appointment ...",
        //   uptime: 1000
        // })
      }
    },
  },
};
</script>

<style></style>
